<template>
  <div class="container">
    <SimpleHeader />
    <!-- <van-cell-group title="" class="card-box"> -->
        <van-cell v-for="(item,index) in list" :key="index" :border="false">
          <template #title>
            <div class="custom-title">
              <span class="info-txt">{{item.title}}</span>
              <span>{{item.nickname}} {{item.levelName?`(${item.levelName})`:''}}</span>
            </div>
          </template>
        </van-cell>
      <!-- </van-cell-group> -->
    <div class="foot-btns">
      <div class="foot-btnbox">
        <button class="foot-btn" @click="loginOut">退出登录</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted,reactive,toRefs,inject } from 'vue'
import { getSuperiorInfo } from '@/api/user'
 import to from 'await-to-js';
import { useStore } from 'vuex';
export default {
  name: 'info',
  components: {
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const store = useStore()
    const state = reactive({
      list:[]
    })

    const getData = async() => {
      let [error,result] = await to(getSuperiorInfo())
      _appLoadFanish()
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        state.list = res
      }
    }
    const loginOut = async () => {
      //  await store.dispatch('userLoginOut')
      Dialog.confirm({
        title: '提示',
        message: '确定退出当前登录吗？',
        confirmButtonColor:'#B08F5D'
      })
        .then(async () => {
         await store.dispatch('userLoginOut')
        })
        .catch(() => {

        });
    }
    onMounted(async () => {
      getData()
    })
    return {
      ...toRefs(state),
      loginOut
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #fff;
  min-height: 100%;
}
.info-txt{
  display: inline-block;
  min-width: 80px;
  color: #999;
}
.foot-btns{
  position: initial;
  left: 0;
  transform: translateX(0);
  margin-top: 30px;
}
.van-cell{
  padding: 13px 16px;
}
</style>